import { Component, Vue, Watch, VModel, Prop } from "vue-property-decorator";

@Component({
  components: {
    Avatars: () => import("@/components/Avatars/Avatars.vue"),
  },
})
export default class AvatarsDialog extends Vue {
  @VModel({ type: Boolean }) private showed!: boolean;
  @Prop({ type: String }) private readonly activeAvatarName!: string;

  private draftAvatarName = "";

  private get canSaveAvatar() {
    return this.draftAvatarName !== this.activeAvatarName;
  }

  @Watch("showed")
  private onChangeShow(showed: boolean) {
    if (!showed) return;

    this.draftAvatarName = this.activeAvatarName;
  }

  private toggleShow(showed = !this.showed) {
    this.showed = showed;
  }

  private onSaveAvatar() {
    this.$emit("save:avatar", this.draftAvatarName);
    this.toggleShow(false);
  }

  private onCancelAvatar() {
    this.$emit("cancel:avatar");
    this.toggleShow(false);
  }
}
